const { SET_RETIREMENT_DATA } = require('./actionTypes');

const initialState = {
  goalId: null,
  userGoalInputId: null,
  user_input: null,
  retirementData: null,
};
const retirement = (state = initialState, action) => {
  switch (action.type) {
    case SET_RETIREMENT_DATA:
      return { ...state, retirementData: action.payload };
    default:
      return state;
  }
  
};
export default retirement;
