module.exports = (socket, callback) => {
    socket.on("connect", () => {
        callback(true);
        console.log(socket.id + " is connected");
        let investorId = localStorage.getItem('investorId');
        if(investorId) {
            console.log(`${investorId} | ${socket.id} Joined`);
            socket.emit('join', investorId);
        }
    });

    socket.on("disconnect", () => {
        callback(false);
        let insId = localStorage.getItem('investorId');
        if(insId) {
            console.log(`${insId} | ${socket.id} Disconnected`);
            socket.emit('socket_disconnected', {insId});
        }
    });
    
    socket.on('paytm_payment', (data) => {
        console.log("Paytm payment status received");
        console.log(data);
        // return toast("Payment Done");
    });
}