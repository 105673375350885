import { CART_COUNT, SET_CART_COUNT, SET_CART_DATA } from "./actionTypes"

const initialState = {
    cartCount: null,
    cartItems: null,
    error: "",
    success: "",
}

const cart = (state = initialState, action) => {
    switch (action.type) {
        case CART_COUNT:
            return { ...state, cartCount: action.payload.cartItems }
        case SET_CART_COUNT:
            return  state;
        case SET_CART_DATA:
            return {...state, cartItems: action.payload }
        default:
            return state;

    }
}

export default cart
