import {
  GET_GOAL,
  SET_CALCULATION_DATA,
  SET_GOAL,
  SET_GOAL_CALC_RESULT,
  SET_GOAL_DETAILS_DATA,
  SET_SELECTED_GOAL_BLOCK,
} from './actionTypes';

export const getGoal = () => {
  return {
    type: GET_GOAL,
  };
};

export const setGoal = goalObject => {
  return {
    type: SET_GOAL,
    payload: goalObject,
  };
};
export const setGaolDetailsData = goalData => {
  return {
    type: SET_GOAL_DETAILS_DATA,
    payload: goalData,
  };
};
export const setGoalCalcResult = goalCalcResult => {
  return {
    type: SET_GOAL_CALC_RESULT,
    payload: goalCalcResult,
  };
};
export const setCalculationDataObj = data => {
  return {
    type: SET_CALCULATION_DATA,
    payload: data,
  };
};
export const setGoalSelectedBlockObj = data => {
  return {
    type: SET_SELECTED_GOAL_BLOCK,
    payload: data,
  };
};
