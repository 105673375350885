const { SET_CHILD_AGE_SLAB } = require('./actionTypes');

const initialState = {
  ageSlab: null,
};

const Childgoal = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHILD_AGE_SLAB:
      return {
        ...state,
        ageSlab: action.payload,
      };
    default:
      return state;
  }
};
export default Childgoal;
