import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { configureStore, MiddlewareArray } from "@reduxjs/toolkit";

import storage from 'redux-persist/lib/storage';
import persistStore from "redux-persist/es/persistStore";
import persistReducer from "redux-persist/es/persistReducer";

import rootReducer from "./reducers"
import rootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// redux Persist
const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: [sagaMiddleware]
// });

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor }