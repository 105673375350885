import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { setActiveNav } from 'store/actions';
import style from './footer.module.scss';
import InvestNowSticyBtn from 'components/Calculators/InvestNowSticyBtn';
const Footer = ({ props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isActive, setIsActive } = useState(1);
  const active = useSelector(state => state.mobNav.active);
  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById('navigationId');
    var items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      if (props.router.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      //activateParentDropdown(matchingMenuItem);
    }
  }, [active]);
  function handleActive(tab) {
    dispatch(setActiveNav(tab));
  }

  function activateParentDropdown(item) {
    // item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      // parent.classList.add('active');
      item.classList.add('active-bottom-nav');

      // li
      const parent2 = parent.parentElement;
      parent2.classList.add('active'); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add('active'); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add('active'); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add('active'); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add('active'); // li
            }
          }
        }
      }
    } else {
      item.classList.remove('active-bottom-nav');
    }
    return false;
  }
  const _onClick = e => {
    e.preventDefault();
  };
  return (
    <React.Fragment>
      {/* <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Investifyd.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
              </div>
            </Col>
          </Row>
        </Container>
      </footer> */}
      {state?.id == 'calculators' && <InvestNowSticyBtn />}
      <nav
        className={
          state?.id == 'calculators' ? 'mobile-nav-hide' : `mobile-nav`
        }
        id="navigationId"
      >
        <Link
          to="/dashboard"
          className={`bloc-icon ${active === 1 ? 'active-bottom-nav' : ''}`}
        >
          <div className="mobile-nav-item">
            <div className="flex-center-just" onClick={e => handleActive(1)}>
              {/* <i className="bx bxs-home-circle font-size-20"></i> */}
              <img
                src={
                  require(`../../assets/images/mobNavSvg/${
                    active == 1 ? `activeHome.png` : `home.png`
                  }`).default
                }
                className={`${style.icon_img}`}
                alt="Home"
              />
            </div>
            <div className="flex-center-just">Home</div>
          </div>
        </Link>
        <div
          onClick={e => {
            handleActive(2);
            let stateData = { tabNo: '1' };
            localStorage.setItem('portfolioTabNo', JSON.stringify(stateData));
            navigate('/portfolio', { state: stateData });
          }}
          className={`bloc-icon ${active === 2 ? 'active-bottom-nav' : ''}`}
          // onClick={e => _onClick(e)}
        >
          <div className="mobile-nav-item">
            <div className="flex-center-just">
              {/* <i className="bx bxs-folder font-size-20"></i> */}
              <img
                src={
                  require(`../../assets/images/mobNavSvg/${
                    active == 2 ? `activePortfolio.svg` : `Portfolio.svg`
                  }`).default
                }
                className={`${style.icon_img}`}
                alt="Portfolio"
              />
            </div>
            <div className="flex-center-just">Portfolio</div>
          </div>
        </div>

        <Link
          to="/invest"
          className={`bloc-icon ${active === 3 ? 'active-bottom-nav' : ''}`}
        >
          <div className="mobile-nav-item" onClick={e => handleActive(3)}>
            <div className="flex-center-just">
              <div className="img_wrap">
                <img
                  src={
                    require(`../../assets/images/Logo/${
                      active == 3 ? `logo_icon_dark.png` : `logo_icon.png`
                    }`).default
                  }
                  alt=""
                  className=""
                />
              </div>
            </div>
            <div style={{ position: 'relative', top: '14px' }}>Invest</div>
          </div>
        </Link>

        <div
          onClick={e => {
            handleActive(4);
            let stateData = { tabNo: '5' };
            localStorage.setItem('portfolioTabNo', JSON.stringify(stateData));
            navigate('/portfolio', { state: stateData });
          }}
          className={`bloc-icon ${active === 4 ? 'active-bottom-nav' : ''}`}
        >
          <div className="mobile-nav-item" onClick={e => handleActive(4)}>
            <div className="flex-center-just">
              {/* <i className="bx bxs-folder font-size-20"></i> */}
              <img
                src={
                  require(`../../assets/images/mobNavSvg/${
                    active == 4 ? `activeCalculator.svg` : `calculator.svg`
                  }`).default
                }
                className={`${style.icon_img}`}
                alt="Calculator"
              />
            </div>
            <div className="flex-center-just">SIP/STP/SWP</div>
          </div>
        </div>
        <div
          className={`bloc-icon ${active === 5 ? 'active-bottom-nav' : ''}`}
          onClick={e => {
            handleActive(5);
            let stateData = { tabNo: '2' };
            localStorage.setItem('portfolioTabNo', JSON.stringify(stateData));
            navigate('/portfolio', { state: stateData });
          }}
        >
          <div className="mobile-nav-item">
            <div className="flex-center-just">
              {/* <i
                className={
                  active === 5
                    ? 'mdi mdi-cart font-size-21'
                    : 'mdi mdi-cart-outline font-size-21'
                }
              ></i> */}
              <img
                src={
                  require(`../../assets/images/mobNavSvg/${
                    active == 5 ? `activeOrder.png` : `order.png`
                  }`).default
                }
                className={`${style.icon_img}`}
                alt="Orders"
              />
            </div>
            <div>Orders</div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Footer;
