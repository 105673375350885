import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, GET_USER, SET_USER_PROFILE, FETCH_USER_PROFILE, SET_PROFILE_PICTURE, ACCOUNT_STATUS } from "./actionTypes"
export const getAccountStatus = status => {
  return {
    type: ACCOUNT_STATUS,
    payload: { status },
  };
};

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}
export const getuser = (user) => {
  return {
    type: GET_USER,
    payload: user,
  }
}
export const fetchUserProfile = (user) => {
  return {
    type: FETCH_USER_PROFILE,
    payload: user,
  }
}
export const setUserProfile = (user) => {
  return {
    type: SET_USER_PROFILE,
    payload: user,
  }
}
export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}
export const setProfilePicture = (file) => {
  return {
    type: SET_PROFILE_PICTURE,
    payload: file,
  }
}
export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
