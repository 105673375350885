import { createContext } from 'react';
import { io } from 'socket.io-client';

let webhostname = '';
if (window.location.hostname === 'app.investifyd.com') {
  webhostname = 'https://app.investifyd.com';
} else if (window.location.hostname === 'stage.app.investifyd.com') {
  webhostname = 'https://stage.app.investifyd.com';
} else {
  webhostname = 'https://stage.app.investifyd.com/api';

  //webhostname = 'http://localhost:4000';
  // webApiHostName = 'http://localhost:4000';
}

let token = localStorage.getItem('authToken');

export const socket = io(webhostname);
export const SocketContext = createContext();


// import { createContext } from 'react';
// import { io } from 'socket.io-client';

// let webhostname = '';
// if (window.location.hostname === 'app.investifyd.com') {
//   webhostname = 'https://app.investifyd.com';
// } else if (window.location.hostname === 'stage.app.investifyd.com') {
//   webhostname = 'https://stage.app.investifyd.com';
// } else {
//   webhostname = 'http://localhost:4000';
//   // webApiHostName = 'http://localhost:4000';
// }

// let socket;
// let SocketContext = createContext();
// let token = localStorage.getItem('authToken');
// if(token) {
//   socket = io(webhostname);
// }

// export {
//   socket, SocketContext
// }