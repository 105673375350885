import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';

//Import images
import avatar3 from '../../../assets/images/users/avatar-3.jpg';
import avatar4 from '../../../assets/images/users/avatar-4.jpg';

//i18n
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [cartCount, setCartCount] = useState('');
  const cartData = useSelector(state => state.Cart.cartCount);

  return (
    <React.Fragment>
      {' '}
      {cartData ? (
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon "
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <Link to={cartData ? '/cart' : ''} style={{ cursor: 'pointer' }}>
              <i
                className={cartData ? 'bx  bx-cart bx-tada' : 'bx  bx-cart '}
              />
              <span className="badge bg-danger rounded-pill">
                {cartData || ''}
              </span>
            </Link>
          </DropdownToggle>
        </Dropdown>
      ) : null}
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
