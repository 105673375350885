import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Row, Col, Collapse } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import classname from 'classnames';

//i18n
import { withTranslation } from 'react-i18next';

import { connect, useSelector } from 'react-redux';
import { isUserAllowed } from 'helpers/utils';
import withRouter from 'components/Common/withRouter';

const Navbar = props => {
  const pathName = useLocation();
  const userProfile = useSelector(state => state.Profile.user);
  const [dashboard, setdashboard] = useState(false);
  const [allowUser, setAllowedUsers] = useState(false);
  const [funds, setFunds] = useState(false);
  useEffect(() => {
    if (pathName.pathname !== '/mutual-funds') {
      var matchingMenuItem = null;
      var ul = document.getElementById('navigation');
      var items = ul.getElementsByTagName('a');
      for (var i = 0; i < items.length; ++i) {
        if (pathName.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    }
  }, [pathName.pathname]);
  useEffect(() => {
    const hostname = window.location.hostname;
    if (isUserAllowed(hostname, userProfile)) {
      setAllowedUsers(true);
    } else {
      setAllowedUsers(false);
    }
  }, []);
  function activateParentDropdown(item) {
    // item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      // parent.classList.add('active');
      item.classList.add('active-nav'); // li
      const parent2 = parent.parentElement;
      parent2.classList.add('active'); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add('active'); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add('active'); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add('active'); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add('active'); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      {location.pathname !== '/mutual-funds' && (
        <div className="" style={{ marginLeft: '130px' }}>
          <div className="">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={props.leftMenu}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav ">
                  <li className="nav-item header-nav-item ">
                    <Link
                      to="/dashboard"
                      className={`nav-link header-nav-link px-3 ${
                        pathName.pathname === '/dashboard' ? 'active' : ''
                      }`}
                      style={{ color: 'rgb(42 52 78)', fontWeight: '500' }}
                    >
                      {/* <i className="bx bx-home-circle me-2"></i> */}
                      {props.t('Home')}
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/goal" className="nav-link arrow-none">
                      <i className="bx bx-target-lock me-2"></i>
                      {props.t('Goal')}
                    </Link>
                  </li> */}
                  {/* <i
                        className="bx bx-pulse me-2"
                      ></i> */}
                  {/* <li className="nav-item header-nav-item">
                    <Link
                      to="/mutual-funds"
                      className="nav-link header-nav-link px-3"
                      style={{ color: 'rgb(42 52 78)', fontWeight: '500' }}
                    >
                      {props.t('Mutual Funds')}
                    </Link>
                  </li> */}
                  <li className="nav-item header-nav-item">
                    <Link
                      className={`nav-link dropdown-toggle header-nav-link px-3 ${
                        pathName.pathname === '/invest' ? 'active' : ''
                      }`}
                      to="/invest"
                      style={{ color: 'rgb(42 52 78)', fontWeight: '500' }}
                    >
                      {props.t('Invest')}
                    </Link>
                  </li>
                  {allowUser && (
                    <li className="nav-item header-nav-item">
                      <Link
                        className={`nav-link dropdown-toggle header-nav-link px-3 ${
                          pathName.pathname === '/goal' ? 'active' : ''
                        }`}
                        to="/goal"
                        style={{ color: 'rgb(42 52 78)', fontWeight: '500' }}
                      >
                        {props.t('Goals')}
                      </Link>
                    </li>
                  )}
                  <li className="nav-item header-nav-item">
                    <Link
                      className={`nav-link header-nav-link px-3 ${
                        pathName.pathname === '/portfolio' ? 'active' : ''
                      }`}
                      to="/portfolio"
                      style={{ color: 'rgb(42 52 78)', fontWeight: '500' }}
                    >
                      {/* <i className="bx bxs-id-card me-2"></i> */}
                      {props.t('Portfolio')}
                    </Link>
                  </li>
                  <li className="nav-item header-nav-item">
                    <Link
                      className={`nav-link header-nav-link px-3 ${
                        pathName.pathname === '/calculator' ? 'active' : ''
                      }`}
                      to="/calculator"
                      style={{ color: 'rgb(42 52 78)', fontWeight: '500' }}
                    >
                      {/* <i className="bx bxs-id-card me-2"></i> */}
                      {props.t('Calculator')}
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
