import React from 'react';
import './pageLoad.scss';
const FullPageLoader = () => {
  return (
    <div id="loading-wrapper2">
      <div id="blurry"></div>
      <div id="content1">
        <img
          src={
            require('../../../assets/images/animatedSvg/Investifyd.svg').default
          }
        />
      </div>
    </div>

    // {/* <div id="loading-wrapper">
    // <div id="loading-text">LOADING...</div>
    // <div id="loading-content"></div>
    // </div> */}
  );
};

export default FullPageLoader;
