import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  currentUser: null,
  error: "",
  loading: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: "" }

    case LOGIN_SUCCESS:
      return { ...state, currentUser: action.payload, loading: false }

    case LOGOUT_USER:
      return initialState

    case LOGOUT_USER_SUCCESS:
      return initialState

    case API_ERROR:
      return { ...state, error: action.payload, loading: false }

    default:
      return state;
  }
}

export default login
