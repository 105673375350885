import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_USER,
  SET_USER_PROFILE,
  GET_USER_PROFILE,
  FETCH_USER_PROFILE,
  SET_PROFILE_PICTURE,
  ACCOUNT_STATUS,
} from './actionTypes';

const initialState = {
  user: null,
  picture: null,
  status: null,
  error: '',
  success: '',
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_STATUS:
      return { ...state, status: action.payload.status };
    case EDIT_PROFILE:
      return { ...state };

    case GET_USER:
      return { ...state, user: action.payload };

    case FETCH_USER_PROFILE:
      return { ...state };

    case SET_USER_PROFILE:
      return { ...state, user: action.payload };

    case PROFILE_SUCCESS:
      return { ...state, success: action.payload };

    case PROFILE_ERROR:
      return { ...state, error: action.payload };

    case RESET_PROFILE_FLAG:
      return { ...state, success: null };

    case SET_PROFILE_PICTURE:
      return { ...state, picture: action.payload };

    default:
      return state;
  }
};

export default profile;
