import React, { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
    const scriptElement = document.createElement('noscript');
    scriptElement.innerHTML =
      '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NPRWF6V" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
    document.body.appendChild(scriptElement);

    return () => {
      // Clean up the script element if the component is unmounted
      document.body.removeChild(scriptElement);
    };
  }, []);

  return null;
};

export default GoogleTagManager;
