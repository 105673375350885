import {
  GET_GOAL,
  SET_CALCULATION_DATA,
  SET_GOAL,
  SET_GOAL_CALC_RESULT,
  SET_GOAL_DETAILS_DATA,
  SET_SELECTED_GOAL_BLOCK,
} from './actionTypes';

const initialState = {
  goalId: null,
  userGoalInputId: null,
  user_input: null,
  goalData: null,
  goalCalcResult: null,
  calculationData: null,
  selectedGoalBlockObj: null,
};

const goal = (state = initialState, action) => {
  switch (action.type) {
    case GET_GOAL:
      return state;
    case SET_GOAL:
      return {
        ...state,
        goalId: action.payload.goalId,
        goalName: action.payload.goalName,
        userGoalInputId: action.payload.userGoalInputId,
        user_input: action.payload,
      };
    case SET_GOAL_DETAILS_DATA:
      return { ...state, goalData: action.payload };
    case SET_GOAL_CALC_RESULT: {
      return { ...state, goalCalcResult: action.payload };
    }
    case SET_CALCULATION_DATA: {
      return { ...state, calculationData: action.payload };
    }
    case SET_SELECTED_GOAL_BLOCK: {
      return { ...state, selectedGoalBlockObj: action.payload };
    }
    default:
      return state;
  }
};

export default goal;
