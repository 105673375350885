import FullPageLoader from 'components/Common/FullPageLoader/FullPageLoader';
import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

// Lazy load components
const Login = lazy(() => import('../pages/Authentication/Login'));
const Logout = lazy(() => import('../pages/Authentication/Logout'));
const ForgetPwd = lazy(() => import('../pages/Authentication/ForgetPassword'));
const ForgetPassword_setnewpass = lazy(() =>
  import('pages/Authentication/ForgetPassword_setnewpass')
);
const Dashboard = lazy(() => import('../pages/Dashboard/index'));
const UserProfile = lazy(() => import('pages/UserProfile'));
const MutualFunds = lazy(() => import('pages/MutualFunds/MutualFunds.page'));
const MutualFundsDetail = lazy(() =>
  import('pages/MutualFundsDetails/MutualFundsDetails.page')
);
const KYCApplication = lazy(() =>
  import('../pages/KYCApplication/KYCApplication')
);
const Payment = lazy(() => import('pages/Payment'));
const FundCart = lazy(() => import('pages/FundCart/FundCart.page'));
const PortfolioMain = lazy(() => import('pages/UserPortfolio'));
const Invest = lazy(() => import('pages/InvestPages'));
const Goal = lazy(() => import('pages/GoalComponent/Goals/index'));
const FundAllocation = lazy(() => import('pages/GoalFundAllocation/index'));
const MFInvestingIdea = lazy(() =>
  import('pages/InvestPages/MFInvestingIdea/MFInvestingIdea')
);
const Goal_new = lazy(() => import('pages/GoalComponent/CommonGoal/index'));
const FastCalculator = lazy(() => import('pages/FastCalculators/index'));
const GoalDetailOld = lazy(() => import('pages/GoalDetailePage/index'));
const GoalDetail = lazy(() =>
  import('pages/GoalComponent/NewGoalDetailPage/index')
);
const UserPortfolioFundDetail = lazy(() =>
  import('pages/UserPortfolioFundDetail/index')
);
const Profile = lazy(() => import('pages/Profile/index'));
const RegisterPage = lazy(() => import('pages/RegistrationFlow/Register2'));
const CompletedOrders = lazy(() => import('pages/CompletedOrders/index'));
const RetryPayment = lazy(() => import('pages/retryPayment/index'));
const ComingSoon = lazy(() => import('components/ComingSoon/ComingSoon'));
const InvestPage = lazy(() => import('pages/InvestPages/InvestPage'));
const Retirement = lazy(() =>
  import('pages/GoalComponent/RetirementGoal/index')
);
const ReserveFund = lazy(() => import('pages/GoalComponent/ReserveFund/index'));
const ChildCalculator = lazy(() =>
  import('pages/GoalComponent/NewChildCalculator/index')
);
const HousingCalculator = lazy(() =>
  import('pages/GoalComponent/HousingCalculator/index')
);
const Child = lazy(() => import('pages/GoalComponent/ChildCalculator/index'));
const KycCompleted = lazy(() => import('pages/KycCompleted/index'));

const SIPCalculator = lazy(() =>
  import('pages/AllCalculators/SIPCalculator/index')
);
const LumpsumCalculator = lazy(() =>
  import('pages/AllCalculators/LumpsumCalculator/index')
);
const PPFCalculator = lazy(() =>
  import('pages/AllCalculators/PPFCalculator/index')
);
const CrorepatiCalculator = lazy(() =>
  import('pages/AllCalculators/CrorepatiCalculator/index')
);
const SipDelayCalculator = lazy(() =>
  import('pages/AllCalculators/SipDelayCalculator/index')
);
const MsscCalculator = lazy(() =>
  import('pages/AllCalculators/MsscCalculator/index')
);
const SSYCalculator = lazy(() =>
  import('pages/AllCalculators/SSYCalculator/index')
);
const EmergencyFundCalculator = lazy(() =>
  import('pages/AllCalculators/EmergencyFundCalculator')
);
const SIPTargetCalculator = lazy(() =>
  import('pages/AllCalculators/SIPTargetCalculator')
);
const ReadyInvestPack = lazy(() => import('pages/ReadyInvestPack/index'));
const RiskProfile = lazy(() => import('components/RiskProfile/RiskProfile'));
const NFO = lazy(() => import('pages/NFO/NFO'));
const completeElog = lazy(() => import('pages/CompleteElog/CompleteElog.jsx'));
const loadingMessage = (
  <div>
    <FullPageLoader />
  </div>
);

const withSuspense = Component => (
  <Suspense fallback={loadingMessage}>
    <Component />
  </Suspense>
);

const authProtectedRoutes = [
  { path: '/risk-profile', component: withSuspense(RiskProfile) },
  { path: '/complete-elog', component: withSuspense(completeElog) },
  { path: '/nfo', component: withSuspense(NFO) },
  { path: '/dashboard', component: withSuspense(Dashboard) },
  { path: '/child', component: withSuspense(Child) },
  { path: '/goal/reserve', component: withSuspense(ReserveFund) },
  { path: '/goal/retirement', component: withSuspense(Retirement) },
  { path: '/goal/child-future', component: withSuspense(ChildCalculator) },
  { path: '/goal/housing-calc', component: withSuspense(HousingCalculator) },
  { path: '/profile', component: withSuspense(Profile) },
  {
    path: '/purchase-order-completed',
    component: withSuspense(CompletedOrders),
  },
  { path: '/profile1', component: withSuspense(UserProfile) },
  { path: '/portfolio', component: withSuspense(PortfolioMain) },
  { path: '/mutual-funds', component: withSuspense(MutualFunds) },
  { path: '/mutual-funds/:isin', component: withSuspense(MutualFundsDetail) },
  { path: '/kyc-application', component: withSuspense(KYCApplication) },
  { path: '/cart', component: withSuspense(FundCart) },
  { path: '/payment', component: withSuspense(Payment) },
  { path: '/sip-calculation', component: withSuspense(SIPCalculator) },
  { path: '/lumpsum-calculation', component: withSuspense(LumpsumCalculator) },
  { path: '/ppf-calculation', component: withSuspense(PPFCalculator) },
  {
    path: '/crorepati-calculation',
    component: withSuspense(CrorepatiCalculator),
  },
  {
    path: '/sip-delay-calculation',
    component: withSuspense(SipDelayCalculator),
  },
  { path: '/mssc-calculation', component: withSuspense(MsscCalculator) },
  { path: '/ssy-calculation', component: withSuspense(SSYCalculator) },
  {
    path: '/emergency-fund-calculation',
    component: withSuspense(EmergencyFundCalculator),
  },
  {
    path: '/sip-target-calculation',
    component: withSuspense(SIPTargetCalculator),
  },
  { path: '/goal/:isin-calculator', component: withSuspense(Goal_new) },
  { path: '/goals/:goalName-detail', component: withSuspense(GoalDetail) },
  { path: '/invest', component: withSuspense(Invest) },
  { path: '/goal', component: withSuspense(Goal) },
  { path: '/fund-allocation', component: withSuspense(FundAllocation) },
  { path: '/fund-invest', component: withSuspense(InvestPage) },
  { path: '/calculator', component: withSuspense(FastCalculator) },
  { path: '/goal/:isin-details', component: withSuspense(GoalDetailOld) },
  {
    path: '/portfolio-funds/:isin',
    component: withSuspense(UserPortfolioFundDetail),
  },
  { path: '/invest-pack', component: withSuspense(ReadyInvestPack) },
  { path: '/retry-payment', component: withSuspense(RetryPayment) },
  { path: '/pages-comingsoon', component: withSuspense(ComingSoon) },
  { path: '/kyc-completed', component: withSuspense(KycCompleted) },
  {
    path: '/',
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: '/logout', component: withSuspense(Logout) },
  { path: '/login', component: withSuspense(Login) },
  { path: '/forgot-password', component: withSuspense(ForgetPwd) },
  {
    path: '/reset_password/:token',
    component: withSuspense(ForgetPassword_setnewpass),
  },
  { path: '/register', component: withSuspense(RegisterPage) },
];

export { publicRoutes, authProtectedRoutes };
