import { GET_ORDER, SET_ORDER } from "./actionTypes"

const initialState = {
    product: null,
    orderData: null,
}

const order = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDER:
            return state;
        case SET_ORDER:
            return { ...state, product: action.payload.product, orderData: action.payload.data }
        default:
            return state;
    }
}

export default order;
