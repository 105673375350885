import { CART_COUNT, SET_CART_COUNT, SET_CART_DATA } from "./actionTypes";


export const getCartCount = (count) => {
    return {
        type: CART_COUNT,
        payload: count,
    }
}
export const setCartCount = () => {
    return {
        type: SET_CART_COUNT,
    }
}
export const setCartData = (data) => {
    return {
        type: SET_CART_DATA,
        payload: data,
    }
}