import React from 'react';
import logo from '../../../assets/images/Logo/logo_trans.png';
import logoLight from '../../../assets/images/Logo/logo_final_white.png';
import logoLightSvg from '../../../assets/images/Logo/logo_final_white.png';
import logoDark from '../../../assets/images/Logo/logo_final.png';
import { Link } from 'react-router-dom';
const AuthHeader = props => {
  return (
    <header id="page-topbar">
      {' '}
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <div className="logo logo-dark">
              <a
                href={
                  window.location.hostname !== 'app.investifyd.com'
                    ? 'https://stage.investifyd.com/'
                    : 'https://investifyd.com/'
                }
                target="_blank"
                rel="noreferrer"
              >
                <span className="logo-sm">
                  <img src={logo} alt="" height="30" />
                </span>

                <span className="logo-lg">
                  <img src={logoDark} alt="" height="30" />
                </span>
              </a>
            </div>

            <div className="logo logo-light">
              {' '}
              <a
                href={
                  window.location.hostname !== 'app.investifyd.com'
                    ? 'https://stage.investifyd.com/'
                    : 'https://investifyd.com/'
                }
                target="_blank"
                rel="noreferrer"
              >
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="30" />
                </span>{' '}
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="19" />
                </span>
              </a>
            </div>
          </div>
          <div></div>
        </div>{' '}
        <div className="d-flex">{props.button}</div>
      </div>
    </header>
  );
};

export default AuthHeader;
