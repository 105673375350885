import { useRef, useCallback } from 'react';

function useThrottle(callback, delay) {
  const lastCall = useRef(0);

  const throttledFunction = useCallback(
    (...args) => {
      const now = new Date().getTime();
      if (now - lastCall.current >= delay) {
        lastCall.current = now;
        callback(...args); // Pass arguments to the callback
      }
    },
    [callback, delay] // Memoize based on the callback and delay
  );

  return throttledFunction;
}

export default useThrottle;
