import React, { useState } from 'react';
import style from './calcDetail.module.scss';
import { Link } from 'react-router-dom';
const InvestNowSticyBtn = () => {
  return (
    <>
      <div className={`${style.stiky_btn}`}>
        <Link
          to="/mutual-funds"
          style={{
            cursor: 'pointer',
            fontWeight: '600',
            color: 'background',
            textDecoration: 'none',
          }}
          className="btn btn-primary"
        >
          Invest Now
        </Link>
      </div>
    </>
  );
};

export default InvestNowSticyBtn;
