const { SET_BANK_DATA, SET_MANDATE_DATA } = require('./actionTypes');

const initialState = {
  bankData: null,
  mandateData: null,
};

const Payment = (state = initialState, action) => {
  switch (action.type) {
    case SET_BANK_DATA:
      return {
        ...state,
        bankData: action.payload,
      };
    case SET_MANDATE_DATA:
      return {
        ...state,
        mandateData: action.payload,
      };
    default:
      return state;
  }
};
export default Payment;
