import { ACTIVE_NAV } from './actionTypes';

const initialState = {
  active: 1,
};
const mobNav = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_NAV:
      return {
        ...state,
        active: action.payload,
      };
    default:
      return state;
  }
};
export default mobNav;
