import { GET_ORDER, SET_ORDER } from "./actionTypes";

export const getOrder = () => {
    return {
        type: GET_ORDER,
    }
}

export const setOrder = (orderObject) => {
    return {
        type: SET_ORDER,
        payload: orderObject,
    }
}