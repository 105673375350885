export * from './layout/actions';

// Authentication module
export * from './auth/register/actions';
export * from './auth/login/actions';
export * from './auth/forgetpwd/actions';
export * from './auth/profile/actions';
export * from './Cart/actions';
export * from './Order/actions';
export * from './Goal/actions';
export * from './Retirement_Calc/actions';
export * from './ChildGoal/actions';
export * from './MobNav/actions';

//dashboard
export * from './dashboard/actions';

//dashboard-saas
export * from './dashboard-saas/actions';

export const resetWebsite = () => ({
  type: 'RESET_WEBSITE',
});
