import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Profile from './auth/profile/reducer';
import Cart from './Cart/reducer';
import Order from './Order/reducer';
import Goal from './Goal/reducer';
import Retirement from './Retirement_Calc/reducer';
import Childgoal from './ChildGoal/reducer';
//Dashboard
import Dashboard from './dashboard/reducer';
import mobNav from './MobNav/reducer';
import Payment from './Payment/reducer';

//Dasboard saas
// import DashboardSaas from "./dashboard-saas/reducer";

// const rootReducer = combineReducers({
//   // public
//   Layout,
//   Login,
//   Profile,
//   Dashboard,
//   // DashboardSaas
// })

const combinedReducer = combineReducers({
  // public
  Layout,
  Login,
  Profile,
  Dashboard,
  Cart,
  Order,
  Goal,
  Retirement,
  Childgoal,
  Payment,
  mobNav,
  // DashboardSaas
});

// add reset website action to root reducer and return
const rootReducer = (state, action) => {
  if (action.type === 'RESET_WEBSITE') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
