import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

//i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// users
import user1 from '../../../assets/images/users/default.png';
import withRouter from 'components/Common/withRouter';

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false);
  const { picture, user } = useSelector(state => state.Profile);

  return (
    <React.Fragment>
      <div className="d-flex">
        <Link to="/profile" className="flex-center-just px-2" title="Profile">
          <img
            className="rounded-circle header-profile-user"
            src={picture ? picture : user1}
            alt="Header Avatar"
          />
        </Link>
        <div className="flex-center-just px-2">
          <Link to="/logout">
            <i
              className="bx bx-log-out-circle font-size-20 align-middle me-1 text-danger"
              title="Logout"
              style={{ transform: 'rotate(180deg)' }}
            />
          </Link>
        </div>
      </div>

      {/* <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-none d-xl-inline-block ms-2 me-1 user_title">
            {user === null ? "Investor" : user.fName === null ? "Investor" : user.fName}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {' '}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t('Profile')}{' '}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown> */}
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
